.react-tooltip {
  z-index: 100;
  min-width: 150px;
  border-radius: 4px !important;
  background-color: black !important;
  text-align: left !important;
  padding: 8px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

@media (max-width: 576px) {
  .react-tooltip {
    width: 200px !important;
  }
}