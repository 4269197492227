.table-row {
    &::after {
        position: absolute;
        left: 100%;
        content: " ";
        display: block;
        border-bottom: 1px solid $grey-200;
        width: 20px;
        top: 100%;
    }
}