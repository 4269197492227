body {
    font-size: 1rem;
}

a:hover {
    text-decoration: none;
}

.height-100 {
    height: calc(100vh - $header-height);
}

.rotate-180 {
    transform: rotate(180deg) scaleX(-1);
}

@media (min-width: 768px) {
    .height-md-100 {
        height: calc(100vh - $header-height);
    }

    .height-100 {
        height: calc(100vh - $header-height);
    }
}


@media (min-width: 768px) {
    .tile {
        height: 335px;

        .legend {
            height: 240px;
        }
    }
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.cursor-pointer {
    cursor: pointer;
}

.info-tooltip {
    position: relative;
    display: inline-block;
}

.info-tooltip .tooltip-text {
    visibility: hidden;
    width: 260px;
    background-color: $dark-blue;
    color: #fff;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 120%;
    text-align: left;

    &::after {
        border-color: transparent $dark-blue transparent transparent;
        content: "";
        position: absolute;
        top: 23px;
        right: 100%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
    }
}

.info-tooltip:hover .tooltip-text {
    visibility: visible;
}

.page-max-width {
    max-width: 1400px;
}

.margin-top-1 {
	margin-top: -1px;
}

.text-decoration-none {
    text-decoration: none;
}