body {
    font-family: 'Source Sans Pro', sans-serif;
}

@media (max-width: 768px) {

    body,
    div,
    a,
    span,
    input,
    input::placeholder {
        font-size: 0.875rem;
    }
}

h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
}

h6 {
    margin-bottom: 0;
}

.fw-semi-bold,
.text-highlight:hover {
    font-weight: 600;
}

.fw-regular {
    font-weight: 400;
}

.text-sm {
    font-size: 0.75rem;
}

.text-md {
    font-size: 0.875rem;
}

.text-regular {
    font-size: 1rem;
}

.no-line-height {
    line-height: 1rem;
}