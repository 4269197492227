/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $grey-200;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

input[type="radio"] {
	height: 20px;
	width: 20px;
	opacity: 0;

	&:checked+label::before {
		border: 2px solid $brand-dark-blue;
	}

	&:checked+label::after {
		content: "";
	}

	&:not(:checked)+label::after {
		content: none;
	}
}

label {
	position: relative;
	cursor: pointer;

	&::before,
	&::after {
		content: "";
		position: absolute;
		border-radius: 50%;
	}

	&::before {
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		border: 2px solid $grey-400;
		background-color: $white;
	}

	&::after {
		left: -15px;
		top: 50%;
		transform: translateY(-50%);
		width: 10px;
		height: 10px;
		background-color: $brand-dark-blue;
		;
	}
}

.form-check {

	.form-check-input {
		height: 1.1rem;
	}

	.form-check-input,
	.form-check-input:active,
	.form-check-input:focus {
		border: none;
		outline: none;
		box-shadow: none;
		background-color: $grey-400;
		--bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
	}

	.form-check-input:checked {
		background-color: $brand-dark-blue;
	}
}