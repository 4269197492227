.dropdown {
    .dropdown-toggle {
        width: 100%;
        color: $dark-blue;
        background-color: $white;
        border: 1.5px solid $grey-200;
    }

    .dropdown-menu {
        width: 100%;
        border: 1.5px solid $grey-200;

        .dropdown-item:hover {
            background-color: $grey-200;
        }
    }

    &.show {
        .dropdown-arrow {
            transform: rotate(180deg);
        }
    }

    .dropdown-toggle::after {
        display: none;
    }
}

.dropdown-search-clear-icon.gray {
    display: none;
}

.dropdown-heading-dropdown-arrow.gray {
    color: $black-100;
}

.rmsc {
    .dropdown-container {
        border: 1.5px solid $grey-200;
        cursor: pointer;
    }

    .dropdown-container:focus-within {
        box-shadow: none;
        border: 1.5px solid $grey-200;
    }

    .dropdown-heading {
        cursor: pointer;
    }

    .dropdown-content {
        padding-top: 0px;

        .panel-content {
            box-shadow: none;
            border: 1.5px solid $grey-200;
            border-radius: 0 0 8px 8px;
        }
    }

    .select-item {
        &.selected {
            background-color: $grey-200;
        }
    }
}

.table-header {
    .dropdown {
        .dropdown-toggle {
            border: none;
            background: none;
            padding: 0;
            text-align: start;

            &:active {
                background: none;
            }

            .current,
            span:hover {
                color: $brand-dark-blue;
                font-weight: 600;
                filter: brightness(0) saturate(100%) invert(17%) sepia(81%) saturate(2792%) hue-rotate(214deg) brightness(85%) contrast(88%);
            }

            @media (max-width: 768px) {

                .current,
                span:hover {
                    color: $black-100;
                    filter: none;
                }
            }
        }

        .dropdown-menu {
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);

            .dropdown-item {
                padding: 4px 4px;



                &.active {
                    color: $brand-dark-blue;
                    background-color: transparent;
                    text-decoration: none;

                    img {
                        filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(2224%) hue-rotate(208deg) brightness(96%) contrast(95%);
                    }
                }

                &:hover {
                    color: $brand-dark-blue;
                    background-color: $grey-200;
                    text-decoration: none;
                }
            }

            @media (max-width: 768px) {
                padding: 8px;

                .dropdown-item {
                    padding: 8px;
                    border-radius: 8px;

                    &.active {
                        color: $brand-dark-blue;
                        text-decoration: none;
                    }

                    &:hover {
                        color: $brand-dark-blue;
                        background-color: $grey-200;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.column-header {
    .dropdown {
        .dropdown-toggle {
            border: none;
            background: none;
            padding: 0;
            text-align: start;

            &:active {
                background: transparent;
            }

            .current,
            span:hover {
                color: $brand-dark-blue;
                font-weight: 600;
                filter: brightness(0) saturate(100%) invert(17%) sepia(81%) saturate(2792%) hue-rotate(214deg) brightness(85%) contrast(88%);
            }
        }

        .dropdown-menu {
            border: 2px solid $grey-200;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);

            .dropdown-item {
                padding: 4px 4px;

                &.active {
                    color: $brand-dark-blue;
                    text-decoration: none;
                    background: transparent;

                    img {
                        filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(2224%) hue-rotate(208deg) brightness(96%) contrast(95%);
                    }
                }

                &:hover {
                    color: $brand-dark-blue;
                    background-color: $grey-200;
                    text-decoration: none;
                }
            }
        }
    }
}

.custom-dropdown {
    position: relative;
    display: inline-block;
    color: $black-100;

    .dropdown-toggle {
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        background-color: $white;
        color: $black-100;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    }

    @media (min-width: 768px) {
        .dropdown-menu {
            right: 0;
        }
    }

    .dropdown-menu li {
        cursor: pointer;
    }

    .dropdown-menu li:hover {
        background-color: $grey-200;
        border-radius: 8px;
    }

    &.open .dropdown-menu {
        display: block;
    }

    .dropdown-item.active {
        background-color: $white;
        color: $brand-dark-blue;

        img {
            filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(2224%) hue-rotate(208deg) brightness(96%) contrast(95%);
        }
    }
}

.img-selected {
    filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(2224%) hue-rotate(208deg) brightness(96%) contrast(95%);
}

.date-picker-tooltip {
    position: absolute;
    z-index: 8;
    color: $dark-blue;
}

.rs-calendar-table-cell-content {
    border-radius: 0;
    color: $black-100;
}

.rs-btn-primary {
    border-radius: 0;
    background-color: $brand-dark-blue;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: $brand-dark-blue;
}

.rs-stack-item .rs-btn-link {
    color: $dark-blue;
    cursor: default;
    opacity: 1;
    text-wrap: balance;
    text-align: start;
    pointer-events: none;
}

@media (max-width: 576px) {
    .dropdown-menu-width {
        width: 360px;
    }
}

@media (min-width: 576px) {
    .dropdown-menu-width {
        width: 400px;
    }
}

.asset-dropdown {
    min-width: fit-content;
}