.c-black-100 {
    color: $black-100;
}

.bc-black-100 {
    color: $black-100;
}

.c-grey-200 {
    color: $grey-200;
}

.bc-grey-200 {
    background-color: $grey-200;
}

.c-grey-400 {
    color: $grey-400;
}

.bc-grey-400 {
    background-color: $grey-400;
}

.c-grey-800 {
    color: $grey-800;
}

.bc-grey-800 {
    background-color: $grey-800;
}

.c-dark-blue {
    color: $dark-blue;
}

.bc-dark-blue {
    background-color: $dark-blue;
}


.c-yellow {
    color: $yellow;
}

.bc-yellow {
    background-color: $yellow;
}

.bc-white {
    background-color: $white;
}

.c-green {
    color: $green;
}

.c-red {
    color: $red;
}

.c-dark-red {
    color: $dark-red;
}

.c-brand-dark-blue {
    color: $brand-dark-blue;
}

.filter-gray {
    filter: brightness(0) saturate(100%) invert(47%) sepia(15%) saturate(0%) hue-rotate(165deg) brightness(104%) contrast(95%);
}