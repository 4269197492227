.box-shadow {
    box-shadow: 0px 0px 28px 0px rgba(26, 38, 63, 0.10);
}

.border-bottom-grey-200 {
    border-bottom: 1px solid $grey-200;
}

.border-top-grey-200 {
    border-top: 1px solid $grey-200;
}

.border-bottom-grey-400 {
    border-bottom: 2px solid $grey-400;
}

.border-radius {
    border-radius: 8px;
}
