.navbar {
    .navbar-toggler:focus {
        box-shadow: none;
        outline: none;
    }

    @media (max-width: 768px) {
        &.show {
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: white;
            z-index: 10;

            .navbar-toggler-icon {
                background-image: url('~/public/images/icons/x.svg');
            }
        }
    }

    .brand-container {
        display: flex;
    }

    @media (max-width: 768px) {
        .brand-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    .navbar-nav {
        .nav-link {
            font-size: 0.875rem;
            color: $black-100;
            font-weight: 400;
            text-decoration: none;
            outline: none;
            box-shadow: none;
            min-width: 100px;

            &:hover,
            &.active {
                font-weight: 600;
            }
        }
    }
}

.header-margin {
    margin-top: $header-height;
}