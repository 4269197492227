.nav-tabs {
    border-bottom: 2px solid $grey-400;
    padding: 0;

    .nav-link {
        color: $grey-800;
        padding: 8px 8px;
        outline: none;
        box-shadow: none;
        transition: none;

        &:active {
            border: none;
        }

        &.active {
            color: $dark-blue;
            font-weight: 600;
            border: none;
            border-bottom: 2px solid $dark-blue;
        }
    }
}

.tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &>.active {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}