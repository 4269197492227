.btn-transparent {
    background-color: transparent;
    padding: 8px 16px;

    &:hover {
        border-radius: 6px;
        background-color: $grey-200;
    }

    &:disabled {
        cursor: pointer;
    }
}