$black-100: #1C1C1C;

$white: #FFFFFF;

$grey-200: #F0F2FA;
$grey-400: #D9DEF2;
$grey-800: #686686;

$light-blue: #35A2CD;
$dark-blue: #1A263F;
$brand-dark-blue: #1335AF;
$yellow: #F1C85E;
$green: #3F7A1A;
$red: #DC3545;
$dark-red: #842029; 
$orange: #EBA059;

$header-height: 59px;