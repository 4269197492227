.bar {
    width: 0;
    height: 16px;
    line-height: 16px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}

@media (max-width: 576px) {
    div:has(> .chart-tooltip) {
        position: absolute;
        left: 50% !important;
        top: 40% !important;
        transform: translate(-50%, -50%) !important;
        width: 300px;
    }
}

@media (max-width: 992px) {
    .bar-chart-height {
        height: 400px;
   }
}

@media (min-width: 992px) {
    .bar-chart-height {
        height: 365px;
   }
}

.users-activity-chart-height {
    height: 380px;
}

.disabled-link {
    pointer-events: none;
    cursor: pointer;
}